import { Injectable } from '@angular/core';
import { Authority } from 'src/app/constants/authority';
import { parseJsonTo, stringToDate } from '../../utils/utils';
import { School } from '../http/school/interfaces/school';
import { User } from '../http/user/interfaces/user';
import { AuthAbstractService } from './auth-abstract.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthImpersonateUserService extends AuthAbstractService {
  async setUserData(user: User): Promise<void> {
    if (!user.role.code) {
      return;
    }

    const authTokenResponse = await this.authService
      .postImpersonateTokenByUserUuid({ membership: user.uuid })
      .toPromise();
    this.localStorageService.setItem('tokenImpersonate', authTokenResponse.accessToken);
    this.localStorageService.setItem('tokenImpersonateExpiration', authTokenResponse.expires);
    this.localStorageService.setItem('userImpersonate', JSON.stringify(user));
    this.setSchoolUserAuthority(user.role.code);
  }

  setSchoolUserAuthority(authority: Authority): void {
    const schoolUserAuthorityString = this.localStorageService.getItem('schoolUserAuthority');

    if (!schoolUserAuthorityString) {
      return;
    }

    const schoolUserAuthority = parseJsonTo<School>(schoolUserAuthorityString);

    if (!schoolUserAuthority) {
      return;
    }

    schoolUserAuthority.role = authority;

    this.localStorageService.setItem('schoolUserAuthorityImpersonate', JSON.stringify(schoolUserAuthority));
  }

  getSchoolUserAuthority(): School | null {
    const schoolUserAuthority = this.localStorageService.getItem('schoolUserAuthorityImpersonate');

    return parseJsonTo<School>(schoolUserAuthority);
  }

  getToken(): string {
    return this.localStorageService.getItem('tokenImpersonate');
  }

  getTokenExpirationDate(): Date | undefined {
    const tokenExpiration = this.localStorageService.getItem('tokenImpersonateExpiration');

    if (tokenExpiration) {
      return stringToDate(tokenExpiration);
    }

    return;
  }

  getUser(): User | null {
    const userImpersonate = this.localStorageService.getItem('userImpersonate');

    return parseJsonTo<User>(userImpersonate);
  }

  async refreshToken(): Promise<boolean> {
    const accessToken = this.getToken();
    const authToken = await this.authService.postRefreshToken(accessToken).toPromise();
    this.localStorageService.setItem('tokenImpersonate', authToken.accessToken);
    this.localStorageService.setItem('tokenImpersonateExpiration', authToken.expires);

    return !this.isTokenExpired();
  }

  logOut(): void {
    const accessToken = this.getToken();
    this.authService.postRevokeToken(accessToken).subscribe(() => {
      this.clear();
      this.redirectToLogin();
    });
  }

  clear(): void {
    this.localStorageService.removeItem('userImpersonate');
    this.localStorageService.removeItem('tokenImpersonate');
    this.localStorageService.removeItem('tokenImpersonateExpiration');
    this.localStorageService.removeItem('schoolUserAuthorityImpersonate');
    this.localStorageService.removeItem('authenticatedUser');
  }
}
