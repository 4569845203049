import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { AuthUserService } from "../services/auth/auth-user.service";
import { switchMap } from "rxjs/operators";
import { Observable, from } from "rxjs";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    constructor(
        private authUserService: AuthUserService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            environment.KeepLoggedIn &&
            (
                !request.url.includes('token/refresh') &&
                !request.url.includes('authcallback')
            ) &&
            this.authUserService.getToken() &&
            this.authUserService.isTokenExpired()
        ) {
            return from(this.authUserService.refreshToken())
                .pipe(switchMap(() => {
                    return next.handle(request);
                }))
        }

        return next.handle(request);
    }
}